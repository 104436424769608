exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-region-js": () => import("./../../../src/pages/region.js" /* webpackChunkName: "component---src-pages-region-js" */),
  "component---src-pages-the-grounds-js": () => import("./../../../src/pages/the-grounds.js" /* webpackChunkName: "component---src-pages-the-grounds-js" */),
  "component---src-pages-the-rooms-js": () => import("./../../../src/pages/the-rooms.js" /* webpackChunkName: "component---src-pages-the-rooms-js" */),
  "component---src-pages-venue-js": () => import("./../../../src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */)
}

